// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyDiMhOF7AJY_h51Ro6Urrz3jSYZJu_xkks",
  authDomain: "albin-jose-capro-pms.firebaseapp.com",
  projectId: "albin-jose-capro-pms",
  storageBucket: "albin-jose-capro-pms.appspot.com",
  messagingSenderId: "987700060956",
  appId: "1:987700060956:web:399f5d8a8e5037f92890d9",
  measurementId: "G-K08CF9DQGG",
  databaseURL:
    "https://albin-jose-capro-pms-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase
const auth = getAuth(app);
const db = getDatabase(app);
const analytics = getAnalytics(app);
export { auth, db };
export const COMPANY_NAME = ""; // "JVA PRIVATE LIMITED";
