import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebase/init";
import {
  getDatabase,
  ref,
  set,
  onValue,
  orderByChild,
} from "firebase/database";

// export async function getDataByDB(database) {
//   console.log("getDataByDB", database);
//   const q = query(collection(db, database), orderBy("fileNo"));
//   const querySnapshot = await getDocs(q);

//   let data = [];
//   querySnapshot.forEach((doc) => {
//     data.push({ key: doc.id, ...doc.data() });
//   });
//   return data;
// }

export async function writeData(id, data, dbName) {
  const db = getDatabase();
  set(ref(db, `${dbName}/` + id), data);
}

export function getDataByDB(dbName) {
  const db = getDatabase();
  const dbRef = ref(db, `${dbName}/`);
  return new Promise((resolve, reject) => {
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      const tableData = Object.keys(data || {}).map((key) => {
        return { ...data[key], key };
      });
      resolve(tableData);
    });
  });
}

export function getDataByDBInDict(dbName) {
  const db = getDatabase();
  const dbRef = ref(db, `${dbName}/`);
  return new Promise((resolve, reject) => {
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      resolve(data);
    });
  });
}

export function getClientsData(dbName, sortKey) {
  const db = getDatabase();
  const empRef = query(ref(db, `${dbName}/`), orderByChild("fileNo"));
  return new Promise((resolve, reject) => {
    onValue(empRef, (snapshot) => {
      const data = snapshot.val();
      const tableData = Object.keys(data || {}).map((key) => {
        return { ...data[key], key };
      });
      resolve(tableData);
    });
  });
}
