export const DB = {
  workTypes: "workTypes",
  workLog: "workLog",
  clients: "clients",
  employee: "employees",
  assigneeStatus: "assigneeStatus",
  adminStatus: "adminStatus",
  credentials: "credentials",
  constitution: "constitution",
  invoice: "invoice",
  timeLog: "timeLog",
};

export const dateFormat = "DD-MM-YYYY";
